/**
* @fileOverview
* @author Zoltan Toth
* @version 0.1
*/

/**
* @description
* Vanilla Javascript tooltip.
*
* @class
* @param {string} [options.theme=dark] - Selects one of the pre-defined tooltip styles - light or dark.
* @param {number} [options.dist=10] - Specifies the distance in pixels from trigger to tooltip.
* @param {number} [options.delay=0] - Specifies how long the tooltip remains visible after the mouse leaves the trigger.
*/

Tooltip = function(options) {
    var theme = options.theme || "dark",
        delay = options.delay || 0,
        dist  = options.distance || 10;
    
    var touch = false;

    try {
        
        document.createEvent("TouchEvent");  
        document.body.classList.add('touch-enable');
        touch = true; 

    } catch (e) {  
        
        touch = false;  
    }  

    /* 
    * Attaching one mouseover and one mouseout listener to the document
    * instead of listeners for each trigger 
    */
    // ['mouseover','touchstart'].forEach( function(evt) {
        // element.addEventListener(evt, dosomething, false);
    // });
    if(touch){
        event = 'touchstart';
    }else{
        event = 'mouseover';
    }

    document.body.addEventListener(event, function(e) {

        var elem = e.target;

        if( !elem.classList.contains('b-tooltip') ){
            
            var check = checkParentsClass(e);
            
            if( check ){
                var t = document.querySelector(".tooltip-active");
                t.classList.remove('tooltip-active');
                
                document.body.removeChild(document.querySelector(".b-tooltip"));
                return;
            }
        }

        if (!e.target.hasAttribute('data-tooltip')){
            
            var check = checkParentsAttr(e);
            
            if( check ){
                elem = check;
            }else{
                return;
            }
        }

        if(elem.classList.contains('tooltip-active')){
            closetooltip(e);
            return;
        }

        elem.classList.add('tooltip-active');

        var tooltip = document.createElement("div");
        tooltip.className = "b-tooltip " + "b-tooltip-" + theme;
        tooltip.innerHTML = elem.getAttribute('data-tooltip');

        document.body.appendChild(tooltip);

        var pos = elem.getAttribute('data-position') || "center top",
            posHorizontal = pos.split(" ")[0];
            posVertical = pos.split(" ")[1];

        positionAt(elem, tooltip, posHorizontal, posVertical);
    });
   
    if(!touch){
        // event = 'touchend';
        document.body.addEventListener('mouseout', function(e) {
            closetooltip(e);
        });
    }else{
        document.body.addEventListener('touchend', function(e) {
            if( e.target.classList.contains('b-tooltip') ){
                e.target.remove();
                e.preventDefault();
                e.stopImmediatePropagation();
                e.stopPropagation();
                var actives = document.querySelectorAll('.tooltip-active');
                [].forEach.call(actives, function(a){
                    a.classList.remove('tooltip-active');
                })
            }
        });
    }

    function closetooltip(e)
    {
        // if(event === 'touchend'){
        //     e.preventDefault();
        //     e.stopImmediatePropagation();
        //     e.stopPropagation();
        // }
        
        var elem = e.target;
        var flag = false;
        
        if (elem.hasAttribute('data-tooltip')) {
            flag = true;
        }else{
            var check = checkParentsAttr(e);
            if( check ){
                elem = check;
                flag = true;
            }else{
                return;
            }
        }

        if(flag){
            elem.classList.remove('tooltip-active');
            setTimeout(function() {
                document.body.removeChild(document.querySelector(".b-tooltip"));
            }, delay);
        }
    }

    /**
     * Positions the tooltip.
     * 
     * @param {object} parent - The trigger of the tooltip.
     * @param {object} tooltip - The tooltip itself.
     * @param {string} posHorizontal - Desired horizontal position of the tooltip relatively to the trigger (left/center/right)
     * @param {string} posVertical - Desired vertical position of the tooltip relatively to the trigger (top/center/bottom)
     * 
     */
    function positionAt(parent, tooltip, posHorizontal, posVertical) {
        var parentCoords = parent.getBoundingClientRect(), left, top;

        switch (posHorizontal) {
            case "left":
                left = parseInt(parentCoords.left) - dist - tooltip.offsetWidth;
                if (parseInt(parentCoords.left) - tooltip.offsetWidth < 0) {
                    left = dist;
                } 
                break;
                
            case "right":
                left = parentCoords.right + dist;
                if (parseInt(parentCoords.right) + tooltip.offsetWidth > document.documentElement.clientWidth) {
                    left = document.documentElement.clientWidth - tooltip.offsetWidth - dist;
                }
                break;

            default:
            case "center":
                left = parseInt(parentCoords.left) + ((parent.offsetWidth - tooltip.offsetWidth) / 2);
        }
        
        switch (posVertical) {
            case "center":
                top = (parseInt(parentCoords.top) + parseInt(parentCoords.bottom)) / 2 - tooltip.offsetHeight / 2;
                break;
                
            case "bottom":
                top = parseInt(parentCoords.bottom) + dist;
                break;

            default:
            case "top":
                top = parseInt(parentCoords.top) - tooltip.offsetHeight - dist;
        }       

        left = (left < 0) ? parseInt(parentCoords.left) : left;
        top  = (top < 0) ? parseInt(parentCoords.bottom) + dist : top;

        tooltip.style.left = left + "px";
        tooltip.style.top  = top + pageYOffset + "px";
    }

    /**
     * Check parents (max 2)
     */
    function checkParentsAttr(elem, count) {
        
        if( !elem || !elem.target )
            return false;
        
        var counter = count || 1;
        var parent  = elem.target.parentNode;

        if( parent && parent.hasAttribute('data-tooltip') ){
            return parent;
        } else if ( counter <= 3 ) {
            counter++;
            checkParentsAttr(parent, counter)
        }
    }

    function checkParentsClass(elem, count) {
        
        if( !elem || !elem.target )
            return false;
        
        var counter = count || 1;
        var parent  = elem.target.parentNode;

        
        if( parent && parent.classList.contains('b-tooltip') ){
            return parent;
        } else if ( counter <= 3 ) {
            counter++;
            checkParentsClass(parent, counter)
        }
    }
};